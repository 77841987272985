html {
  box-sizing: border-box;
  font-family: "Source Sans Pro";
  height: 100%;
}

*,
*:before,
*:after {
  box-sizing: inherit;
}

html,
body,
#root {
  height: 100%;
}

$green: #3db015;
$red: #d84646;

#toolbar {
  display: flex;
  justify-content: space-between;
  width: 100%;
  height: 60px;
  padding: 5px;

  h1 {
    margin: 0;
  }
}

.button {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 8px;
  border: 1px solid $green;
  text-align: center;
  text-transform: uppercase;
  text-decoration: none;
  font-size: 16px;

  color: $green;
  background-color: #fff;

  &:hover {
    background-color: $green;
    color: #fff;
  }

  svg {
    margin-left: 3px;
    vertical-align: auto;
  }
}

#editor-buttons {
  position: fixed;
  right: calc(50% + 20px);
  z-index: 1;
  margin: 10px;

  .button {
    margin-bottom: 10px;
  }
}

#playground {
  display: flex;
}

#editor,
#console {
  position: absolute;
  width: 50%;
  overflow-y: scroll;
  height: 100%;
}

#editor {
  left: 0;
}

#console {
  right: 0;
}

#console-status {
  display: flex;

  div {
    height: 30px;
    flex: 1 1 0;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #fff;
    background-color: #666;
    text-transform: uppercase;

    &:last-child {
      border-left: 1px solid #fff;
    }

    &.console-status-ok {
      background-color: $green;
    }

    &.console-status-error {
      background-color: $red;
    }
  }

  svg {
    vertical-align: bottom;
  }
}

.console-log-entry {
  padding: 15px;

  &:nth-child(odd) {
    background-color: #f3f9ff;
  }
}

.console-error {
  padding: 15px;
  background: $red;
  color: #fff;
}

#console-compiled {
  padding: 15px;

  pre {
    font-family: "Source Code Pro", monospace;
    width: 100%;
    overflow: scroll;
  }
}
